<template>
  <com-mon>
    <div>
      <div>
        <el-card>
          <div class = "toptitle">
            <span>配置客服工具栏</span>
            <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnyuvvQeYC6MLxOP0pIBO7Sh" type="primary" style="margin-left:30px"
            :underline="false" target="_blank">如何配置客服工具栏？</el-link>
          </div>
          <div class = "flexRow" style="margin-top:30px;align-items:stretch">
            <div class="demobox">
              <div class = "flexcrosswise grayfont14">
                <div class = "bluediv"></div>
                <span style="margin:-5px 0px 0px 5px">访客信息:客服接入客户会话后，可在会话框右边工具栏查看到客户信息，方便客服更好的为客户提供服务</span>
              </div>
              <div class = "flexcrosswise grayfont14" style="margin-top:20px">
                <span>{{link1}}</span>
                <el-button style="margin:0px 0px 0px 20px;padding:0px" type="text"  v-clipboard:copy="link1"
                v-clipboard:success="onCopy" v-clipboard:error="onError">点击复制地址</el-button>
              </div>
              <img :src="imgurl+'/image/servicetoolbarkehuhuaxiang.png'" style="width:100%;margin-top:20px" alt="">
            </div>
            <div class = "line"></div>
            <div class="demobox">
              <div class = "flexcrosswise grayfont14">
                <div class = "bluediv"></div>
                <span style="margin:-5px 0px 0px 5px">访客订单:客服接入客户会话后，可通过该页面查看并查询客户在商城内的订单，支持对订单设置备注，修改收货地址等，便利客服处理订单相关业务</span>
              </div>
              <div class = "flexcrosswise grayfont14" style="margin-top:20px">
                <span>{{link2}}</span>
                <el-button style="margin:0px 0px 0px 20px;padding:0px" type="text"  v-clipboard:copy="link2"
                v-clipboard:success="onCopy" v-clipboard:error="onError">点击复制地址</el-button>
              </div>
              <img :src="imgurl+'/image/servicetoolbarkehudingdan.png'" style="width:100%;margin-top:20px" alt="">
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </com-mon>
</template>

<script>
import Commom from "@/views/customerServe/component/Common.vue"//引入组件
import config from "@/config"
export default {
  components:{ "com-mon":Commom},
  data () {
    return {
      imgurl:config.IMG_BASE,
      link1:'',
      link2:''
    }
  },
  created () {
    // console.log(location.origin)
    this.mallkey = window.localStorage.getItem('mlmzMallKey')
    if (process.env.NODE_ENV == 'production'){
      this.link1 = 'https://wapapi.qidian.shop/tools/#/userInfo?isqy=1&mallkey='+this.mallkey
      this.link2 = 'https://wapapi.qidian.shop/tools/#/userOrder?isqy=1&mallkey='+this.mallkey
    }else{
      this.link1 = 'https://wap.dkyapitest.cn/tools/#/userInfo?isqy=1&mallkey='+this.mallkey
      this.link2 = 'https://wap.dkyapitest.cn/tools/#/userOrder?isqy=1&mallkey='+this.mallkey
    }
  },
  methods: {
    onCopy(){
      this.$message.success('复制成功')
    },
    onError(){
      this.$message.error('复制失败')
    },
  }
}
</script>

<style lang = "less" scoped>
  .toptitle{
    font-size: 16px;
    font-weight: bold;
    color: #303133;
  }
  .flexRow{
    display: flex;
    /* align-items: flex-start; */
    justify-content: space-between;
    /* border:1px solid black */
  }
  .line{
    width: 2px;
    /* border: 1px solid #DCDFE6; */
    background: #DCDFE6;
    margin: 0px 10px;
    flex-shrink: 0;
  }
  .grayfont14{
    color: #606266;
    line-height: 1.5;
    font-size: 14px;
  }
  .demobox{
    padding: 10px;
    width: calc(50%-20px);
    .flexcrosswise{
      display: flex;
      /* align-items: flex-start; */
      .bluediv{
        width: 2px;
        height: 10px;
        background: #409EEF;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        flex-shrink: 0;
      }
    }
  }
</style>